import React from "react";

const PackageIconoServiciosPaqueteVacacional = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
  >
    <g clipPath="url(#clip0_3400_17614)">
      <path
        d="M2.68177 6.12349C2.05945 6.12349 1.51035 5.67349 1.40052 4.99849C1.2907 4.24849 1.7666 3.57349 2.46213 3.46099C2.7916 3.38599 3.15767 3.49849 3.45052 3.68599C3.74338 3.87349 3.92642 4.17349 3.99963 4.54849C4.07285 4.88599 3.96302 5.26099 3.77999 5.56099C3.56035 5.86099 3.26749 6.04849 2.93802 6.12349C2.8282 6.12349 2.75499 6.12349 2.68177 6.12349ZM2.68177 4.17349C2.64517 4.17349 2.60856 4.17349 2.57195 4.17349C2.2791 4.24849 2.05945 4.54849 2.13267 4.88599C2.20588 5.22349 2.49874 5.41099 2.7916 5.37349C2.93802 5.33599 3.08445 5.26099 3.15767 5.14849C3.23088 5.03599 3.26749 4.84849 3.26749 4.69849C3.23088 4.54849 3.15767 4.39849 3.04785 4.32349C2.93802 4.21099 2.8282 4.17349 2.68177 4.17349Z"
        fill="currentColor"
      />
      <path
        d="M2.68258 7.43647C1.43794 7.43647 0.339726 6.49897 0.120083 5.18647C-0.136167 3.76147 0.852226 2.37397 2.2799 2.14896C2.93883 1.99896 3.63437 2.18646 4.22008 2.59896C4.8058 3.01146 5.17187 3.61146 5.28169 4.32397C5.53794 5.78647 4.54955 7.17397 3.12187 7.39897C2.97544 7.43647 2.82901 7.43647 2.68258 7.43647ZM2.68258 2.86147C2.57276 2.86147 2.46294 2.86147 2.35312 2.89897C1.32812 3.08647 0.632583 4.06147 0.815619 5.11147C0.888833 5.63647 1.18169 6.04897 1.58437 6.34897C1.98705 6.64897 2.49955 6.76147 2.97544 6.68647C4.00044 6.49897 4.69598 5.52397 4.51294 4.47397C4.43973 3.94897 4.14687 3.53647 3.74419 3.23647C3.45133 2.97397 3.08526 2.86147 2.68258 2.86147Z"
        fill="currentColor"
      />
      <path
        d="M11.7969 14.5605H8.13616C7.3308 14.5605 6.67188 13.8855 6.67188 13.0605C6.67188 12.2355 7.3308 11.5605 8.13616 11.5605H11.7969C12.6022 11.5605 13.2612 12.2355 13.2612 13.0605C13.2612 13.8855 12.6022 14.5605 11.7969 14.5605ZM8.13616 12.3105C7.73348 12.3105 7.40402 12.648 7.40402 13.0605C7.40402 13.473 7.73348 13.8105 8.13616 13.8105H11.7969C12.1996 13.8105 12.529 13.473 12.529 13.0605C12.529 12.648 12.1996 12.3105 11.7969 12.3105H8.13616Z"
        fill="currentColor"
      />
      <path
        d="M17.0321 10.0645H15.3848C15.2749 10.0645 15.1651 10.027 15.0919 9.91445C15.0187 9.83945 14.9821 9.68945 15.0187 9.57695L15.9705 5.78945C15.7142 5.60195 15.5312 5.33945 15.3482 5.03945C14.616 3.76445 15.0187 2.11445 16.2633 1.36445C16.4464 1.25195 16.666 1.13945 16.8857 1.10195C16.9955 1.06445 17.1419 1.10195 17.2517 1.21445C17.3616 1.32695 17.3982 1.43945 17.3616 1.58945L16.8491 3.68945L17.508 4.17695L18.3133 4.06445L18.8258 1.96445C18.8624 1.85195 18.9723 1.73945 19.0821 1.70195C19.1919 1.66445 19.3383 1.70195 19.4482 1.81445C19.6312 2.00195 19.741 2.15195 19.8874 2.37695C20.2535 2.97695 20.3267 3.72695 20.1803 4.40195C19.9973 5.11445 19.558 5.67695 18.9723 6.05195C18.7526 6.20195 18.4964 6.27695 18.2401 6.35195L17.3616 9.80195C17.3616 9.95195 17.2151 10.0645 17.0321 10.0645ZM15.8607 9.31445H16.7392L17.6178 5.90195C17.6544 5.75195 17.7642 5.63945 17.9107 5.63945C18.1669 5.60195 18.3866 5.52695 18.6062 5.37695C19.0455 5.11445 19.3383 4.70195 19.4848 4.17695C19.5946 3.76445 19.558 3.31445 19.3749 2.93945L18.9723 4.47695C18.9357 4.62695 18.8258 4.73945 18.6794 4.73945L17.4714 4.88945C17.3616 4.88945 17.2883 4.88945 17.2151 4.81445L16.2267 4.06445C16.1169 3.98945 16.0437 3.80195 16.0803 3.65195L16.483 2.11445C15.7508 2.71445 15.5312 3.76445 16.0071 4.62695C16.1535 4.88945 16.3732 5.11445 16.5928 5.26445C16.7392 5.33945 16.7758 5.52695 16.7392 5.67695L15.8607 9.31445Z"
        fill="currentColor"
      />
      <path
        d="M10.1495 6.08783C9.74681 5.93783 9.56377 5.45033 9.78342 5.07533C9.96645 4.73783 10.1495 4.28783 11.4307 3.20033C11.6504 3.01283 12.1263 2.71283 12.1263 2.71283C12.3459 2.56283 12.6388 2.67533 12.7486 2.90033L13.4441 4.21283C13.6638 4.66283 13.5173 5.18783 13.1513 5.52533L12.4923 6.12533C12.1629 6.42533 11.7968 6.50033 11.3209 6.38783C10.9915 6.35033 10.1495 6.08783 10.1495 6.08783Z"
        fill="currentColor"
      />
      <path
        d="M3.45059 9.94924C3.45059 9.94924 4.32916 8.89924 4.65862 8.52424L4.32916 6.61174C4.29255 6.49924 4.21934 6.38674 4.10951 6.31174C3.99969 6.27424 3.85326 6.27424 3.74344 6.34924C3.5238 6.49924 3.26755 6.61174 3.0113 6.68674C2.75505 6.72424 2.46219 6.72424 2.20594 6.64924C2.09612 6.61174 1.94969 6.64924 1.87648 6.72424C1.76666 6.79924 1.73005 6.94924 1.76666 7.06174L2.20594 9.76174C2.24255 9.94924 2.38898 10.0617 2.57201 10.0617H3.59701L3.45059 9.94924ZM2.53541 7.43674C2.71844 7.43674 2.93809 7.43674 3.12112 7.39924C3.30416 7.36174 3.48719 7.32424 3.67023 7.24924L3.92648 8.82424L3.59701 9.31174H2.86487L2.53541 7.43674Z"
        fill="currentColor"
      />
      <path
        d="M4.44007 9.3145C4.69632 9.0145 4.91596 8.752 4.98917 8.677C5.57489 8.077 6.41685 7.327 7.07578 6.9895C7.149 6.952 7.25882 6.9145 7.36864 6.8395C7.55167 6.727 7.77132 6.6145 7.99096 6.5395C8.02757 6.5395 8.06417 6.502 8.10078 6.4645L8.97935 5.6395C9.01596 5.602 9.05257 5.5645 9.05257 5.527C9.16239 5.302 9.30882 5.1145 9.41864 4.927C9.49185 4.8145 9.56507 4.7395 9.60167 4.6645C9.96774 4.027 10.8097 3.2395 11.4686 2.7145C11.6517 2.5645 12.3472 2.077 12.8231 1.777L13.0794 2.077C12.9695 2.1895 12.9329 2.377 13.0061 2.527L13.8481 4.1395C14.1044 4.627 13.9945 5.2645 13.5919 5.6395L12.5669 6.577C12.2008 6.9145 11.6517 7.027 11.1392 6.802L9.41864 6.277C9.30882 6.2395 9.16239 6.277 9.08917 6.352L8.83292 6.6145C8.7231 6.6895 8.68649 6.8395 8.7231 6.952L9.05257 8.7895C9.08917 8.977 9.12578 9.1645 9.08917 9.3145H9.82132C9.82132 9.0895 9.82132 8.827 9.7481 8.602L9.49185 7.0645L10.9195 7.477C11.6517 7.777 12.4936 7.627 13.0794 7.102L14.1044 6.1645C14.7633 5.5645 14.9463 4.5895 14.5436 3.7645L13.8481 2.4145L13.9213 2.3395C14.0677 2.1895 14.0677 1.9645 13.9579 1.8145L13.2258 0.989498C13.116 0.839498 12.8963 0.839498 12.7499 0.914498C12.6767 0.951998 11.3954 1.852 11.1026 2.077C10.6999 2.377 9.52846 3.3145 8.97935 4.252C8.94274 4.327 8.86953 4.402 8.83292 4.5145C8.7231 4.702 8.57667 4.927 8.46685 5.152L7.6981 5.8645C7.44185 5.977 7.22221 6.0895 7.03917 6.202C6.92935 6.2395 6.85614 6.3145 6.78292 6.352C5.83114 6.802 4.76953 7.8895 4.47667 8.1895C4.33025 8.377 3.81775 8.9395 3.48828 9.352H4.44007V9.3145Z"
        fill="currentColor"
      />
      <path
        d="M8.61237 9.31459C8.61237 9.16459 8.61237 9.05209 8.61237 8.86459C8.57577 8.56459 8.42934 7.70209 8.42934 7.70209C8.31952 7.28959 7.88023 7.06459 7.47755 7.21459C7.11148 7.36459 6.6722 7.51459 5.46416 8.71459C5.28112 8.90209 5.02487 9.23959 4.95166 9.35209H8.61237V9.31459Z"
        fill="currentColor"
      />
      <path
        d="M18.387 21.3145H1.54771C1.32807 21.3145 1.18164 21.1645 1.18164 20.9395V9.68945C1.18164 9.46445 1.32807 9.31445 1.54771 9.31445H18.387C18.6066 9.31445 18.7531 9.46445 18.7531 9.68945V20.9395C18.7531 21.1645 18.57 21.3145 18.387 21.3145ZM1.91378 20.5645H18.0209V10.0645H1.91378V20.5645Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3400_17614">
        <rect
          width="20.2071"
          height="20.4375"
          fill="transparent"
          transform="translate(0.0830078 0.875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PackageIconoServiciosPaqueteVacacional;
