import React from "react";

const PackageIconoServiciosCambioFrentos = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6659 2.75C7.67892 2.75 3.63616 6.89137 3.63616 12C3.63616 17.1086 7.67892 21.25 12.6659 21.25C17.6529 21.25 21.6957 17.1086 21.6957 12C21.6957 6.89137 17.6529 2.75 12.6659 2.75ZM2.17188 12C2.17188 6.06294 6.87022 1.25 12.6659 1.25C18.4616 1.25 23.16 6.06294 23.16 12C23.16 17.9371 18.4616 22.75 12.6659 22.75C6.87022 22.75 2.17188 17.9371 2.17188 12Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0499 7.60313C10.7137 7.89541 10.4695 8.34102 10.4695 9.00001C10.4695 9.41422 10.1417 9.75001 9.73735 9.75001C9.333 9.75001 9.00521 9.41422 9.00521 9.00001C9.00521 7.90899 9.43216 7.04209 10.1026 6.45937C10.7571 5.89048 11.6037 5.625 12.4219 5.625C13.2401 5.625 14.0867 5.89049 14.7412 6.45938C15.4116 7.0421 15.8385 7.90899 15.8385 9.00001C15.8385 9.76589 15.647 10.3495 15.3342 10.8301C15.0714 11.234 14.7299 11.5462 14.4611 11.7919C14.4318 11.8187 14.4033 11.8447 14.3759 11.87C14.0761 12.1464 13.8501 12.3743 13.6844 12.6741C13.5259 12.9609 13.3981 13.3616 13.3981 13.9999C13.3981 14.4142 13.0703 14.7499 12.6659 14.7499C12.2616 14.7499 11.9338 14.4142 11.9338 13.9999C11.9338 13.1383 12.111 12.4765 12.4101 11.9352C12.702 11.4069 13.0861 11.041 13.3964 10.755C13.4205 10.7328 13.444 10.7112 13.4669 10.6901C13.76 10.4204 13.9619 10.2347 14.1159 9.99803C14.2607 9.77553 14.3743 9.48414 14.3743 9.00001C14.3743 8.34103 14.1301 7.89542 13.7938 7.60314C13.4416 7.29702 12.9459 7.125 12.4219 7.125C11.8978 7.125 11.4021 7.29702 11.0499 7.60313Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1655 17.4414C13.466 17.7185 13.4904 18.1927 13.2199 18.5006L13.2101 18.5117C12.9396 18.8196 12.4767 18.8446 12.1761 18.5675C11.8756 18.2904 11.8512 17.8162 12.1217 17.5083L12.1315 17.4972C12.402 17.1893 12.8649 17.1643 13.1655 17.4414Z"
      fill="currentColor"
      stroke="transparent"
      strokeWidth="0.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PackageIconoServiciosCambioFrentos;
