import React from "react";

const PackageIconoServiciosPulidoEncerado = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_3400_11495)">
      <path
        d="M18.7623 5.75273C18.6158 5.75273 18.506 5.67773 18.4328 5.56523L17.7739 4.32773L16.5658 3.65273C16.456 3.57773 16.3828 3.46523 16.3828 3.31523C16.3828 3.16523 16.456 3.05273 16.5658 2.97773L17.7739 2.30273L18.4328 1.06523C18.5426 0.840234 18.9453 0.840234 19.0917 1.06523L19.7507 2.30273L20.9587 2.97773C21.0685 3.05273 21.1417 3.16523 21.1417 3.31523C21.1417 3.46523 21.0685 3.57773 20.9587 3.65273L19.7507 4.32773L19.0917 5.56523C19.0185 5.67773 18.9087 5.75273 18.7623 5.75273ZM17.5176 3.31523L18.2498 3.72773C18.2864 3.76523 18.323 3.80273 18.3596 3.87773L18.7623 4.62773L19.165 3.87773C19.2016 3.80273 19.2382 3.76523 19.3114 3.72773L20.0435 3.31523L19.3114 2.90273C19.2382 2.86523 19.2016 2.82773 19.165 2.75273L18.7623 2.00273L18.3596 2.75273C18.323 2.82773 18.2864 2.86523 18.2132 2.90273L17.5176 3.31523Z"
        fill="#001450"
      />
      <path
        d="M3.71629 20.4492C3.56987 20.4492 3.46004 20.3742 3.38683 20.2617L2.65469 18.8742L1.30022 18.1242C1.1904 18.0117 1.11719 17.8992 1.11719 17.7492C1.11719 17.5992 1.1904 17.4867 1.30022 17.4117L2.65469 16.6617L3.38683 15.2742C3.49665 15.0492 3.89933 15.0492 4.04576 15.2742L4.7779 16.6617L6.13237 17.4117C6.24219 17.4867 6.3154 17.5992 6.3154 17.7492C6.3154 17.8992 6.24219 18.0117 6.13237 18.0867L4.7779 18.8367L4.04576 20.2242C3.97254 20.3742 3.86272 20.4492 3.71629 20.4492ZM2.2154 17.7492L3.09397 18.2367C3.16719 18.2742 3.20379 18.3117 3.2404 18.3867L3.71629 19.2867L4.19219 18.3867C4.22879 18.3117 4.2654 18.2742 4.33862 18.2367L5.21719 17.7492L4.33862 17.2617C4.2654 17.2242 4.22879 17.1867 4.19219 17.1117L3.71629 16.2117L3.2404 17.1117C3.20379 17.1867 3.16719 17.2242 3.09397 17.2617L2.2154 17.7492Z"
        fill="#001450"
      />
      <path
        d="M14.148 0.6875L14.5873 1.475L15.3194 1.8875L14.5873 2.3L14.148 3.0875L13.7453 2.3L12.9766 1.8875L13.7453 1.475L14.148 0.6875Z"
        fill="#001450"
      />
      <path
        d="M18.2867 7.51367L18.6893 8.26367L19.4581 8.71367L18.6893 9.12617L18.2867 9.87617L17.884 9.12617L17.1152 8.71367L17.884 8.26367L18.2867 7.51367Z"
        fill="#001450"
      />
      <path
        d="M12.7955 18.4277L13.1981 19.1777L13.9303 19.6277L13.1981 20.0402L12.7955 20.8277L12.3562 20.0402L11.624 19.6277L12.3562 19.1777L12.7955 18.4277Z"
        fill="#001450"
      />
      <path
        d="M9.42631 15.502L9.82899 16.2895L10.5977 16.702L9.82899 17.1145L9.42631 17.902L9.02363 17.1145L8.25488 16.702L9.02363 16.2895L9.42631 15.502Z"
        fill="#001450"
      />
      <path
        d="M8.51109 12.0898C6.90037 12.5023 5.21644 12.0148 4.00841 10.8148C2.10484 8.93984 2.06823 5.78984 3.89859 3.83984C4.81376 2.90234 6.0218 2.37734 7.30305 2.33984C8.5843 2.33984 9.82894 2.82734 10.7441 3.76484C12.062 5.07734 12.5379 7.02734 11.9522 8.86484C11.9155 8.97734 11.8057 9.08984 11.6959 9.12734C11.5861 9.16484 11.4397 9.12734 11.3664 9.01484L8.47448 6.12734C8.18162 5.82734 7.77894 5.63984 7.33966 5.67734C6.90037 5.67734 6.49769 5.86484 6.20483 6.16484C5.58251 6.80234 5.61912 7.85234 6.24144 8.48984L9.0968 11.3398C9.17001 11.4148 9.24323 11.5648 9.20662 11.6773C9.17001 11.7898 9.0968 11.9023 8.98698 11.9398C8.84055 12.0148 8.65751 12.0523 8.51109 12.0898ZM6.38787 3.20234C5.65573 3.38984 4.96019 3.76484 4.41109 4.36484C2.83698 6.01484 2.87359 8.67734 4.4843 10.3273C5.47269 11.3023 6.82716 11.7148 8.14501 11.4523L5.72894 9.05234C4.81376 8.11484 4.77716 6.61484 5.69233 5.67734C6.13162 5.22734 6.71733 4.96484 7.33966 4.96484C7.96198 4.96484 8.54769 5.18984 8.98698 5.63984L11.403 8.03984C11.6227 6.68984 11.22 5.30234 10.2316 4.32734C9.46287 3.53984 8.40126 3.12734 7.30305 3.12734C7.01019 3.08984 6.68073 3.12734 6.38787 3.20234Z"
        fill="#001450"
      />
      <path
        d="M8.91379 13.8881C6.71736 14.4131 4.37451 13.8131 2.72719 12.1631C1.48254 10.8881 0.787007 9.16312 0.7504 7.36312C0.713793 5.56312 1.40933 3.83812 2.61736 2.56312C5.17986 -0.0993766 9.35308 -0.174377 11.9888 2.45062C14.0388 4.51312 14.5879 7.62562 13.3066 10.2881C13.27 10.4006 13.1602 10.4756 13.0504 10.4756C12.9406 10.5131 12.8308 10.4756 12.7209 10.3631L8.47451 6.12562C8.18165 5.82562 7.77897 5.63812 7.33968 5.67562C6.9004 5.67562 6.49772 5.86312 6.20486 6.16312C5.58254 6.80062 5.61915 7.85062 6.24147 8.48812L10.4879 12.7256C10.5611 12.8006 10.5977 12.9131 10.5977 13.0631C10.5977 13.1756 10.5245 13.2881 10.4147 13.3256C9.93879 13.5506 9.42629 13.7381 8.91379 13.8881ZM5.98522 1.40062C4.92361 1.66312 3.93522 2.22562 3.16647 3.05062C2.06826 4.21312 1.44594 5.75062 1.48254 7.36312C1.51915 8.97562 2.14147 10.4756 3.27629 11.6006C4.96022 13.2881 7.48611 13.7756 9.64593 12.8756L5.72897 8.97562C4.81379 8.03812 4.77719 6.53812 5.69236 5.60062C6.13165 5.15062 6.71736 4.88812 7.33968 4.88812C7.96201 4.88812 8.54772 5.11312 8.98701 5.56312L12.8674 9.42562C13.7093 7.17562 13.1602 4.62562 11.4763 2.93812C10.3415 1.81312 8.87718 1.21312 7.30308 1.21312C6.86379 1.25062 6.3879 1.32562 5.98522 1.40062Z"
        fill="#001450"
      />
      <path
        d="M11.916 14.6762C11.6963 14.7512 11.4401 14.6762 11.2204 14.4887L5.72938 8.97617C4.8142 8.03867 4.77759 6.53867 5.69277 5.60117C6.13205 5.15117 6.71777 4.88867 7.34009 4.88867C7.96241 4.88867 8.54813 5.11367 8.98741 5.56367L14.4785 11.0762C14.6249 11.2262 14.6981 11.4137 14.6981 11.6012C14.6981 11.7887 14.6249 11.9762 14.4785 12.1262L12.2454 14.4887C12.1722 14.6012 12.0624 14.6387 11.916 14.6762ZM7.01063 5.67617C6.71777 5.75117 6.42491 5.90117 6.24188 6.12617C5.61955 6.76367 5.65616 7.81367 6.27848 8.45117L11.7696 13.9637L14.0026 11.6012L8.47491 6.12617C8.18205 5.82617 7.77938 5.63867 7.34009 5.67617C7.23027 5.63867 7.12045 5.67617 7.01063 5.67617Z"
        fill="#001450"
      />
      <path
        d="M16.9668 18.0898C16.857 18.1273 16.7106 18.1273 16.6008 18.1273C16.1615 18.1273 15.7588 17.9773 15.4659 17.6398L12.0249 14.1898C11.8784 14.0398 11.8784 13.8148 12.0249 13.6648L13.782 11.8273C13.8552 11.7523 13.9284 11.7148 14.0383 11.7148C14.1481 11.7148 14.2213 11.7523 14.2945 11.8273L17.7356 15.2773C18.3579 15.9148 18.3945 16.9648 17.7722 17.6023C17.5159 17.8648 17.2597 18.0148 16.9668 18.0898ZM12.7936 13.9648L15.9784 17.1523C16.3445 17.4898 16.8936 17.4898 17.2231 17.1523C17.5525 16.7773 17.5525 16.2148 17.2231 15.8773L14.0383 12.6898L12.7936 13.9648Z"
        fill="#001450"
      />
      <path
        d="M13.3797 15.3492C13.2699 15.3867 13.1235 15.3492 13.0503 15.2367C12.9038 15.0867 12.9038 14.8617 13.0503 14.7117L14.8074 12.8742C14.9538 12.7242 15.1735 12.7242 15.3199 12.8742C15.4663 13.0242 15.4663 13.2492 15.3199 13.3992L13.5628 15.2367C13.4895 15.2742 13.4529 15.3117 13.3797 15.3492Z"
        fill="#001450"
      />
      <path
        d="M14.4051 16.3648C14.2953 16.4023 14.1489 16.3648 14.0756 16.2523C13.9292 16.1023 13.9292 15.8773 14.0756 15.7273L15.8328 13.8898C15.9792 13.7398 16.1989 13.7398 16.3453 13.8898C16.4917 14.0398 16.4917 14.2648 16.3453 14.4148L14.5881 16.2523C14.5149 16.3273 14.4417 16.3648 14.4051 16.3648Z"
        fill="#001450"
      />
      <path
        d="M15.4305 17.3746C15.3207 17.4121 15.1742 17.3746 15.101 17.2621C14.9546 17.1121 14.9546 16.8871 15.101 16.7371L16.8582 14.8996C17.0046 14.7496 17.2242 14.7496 17.3707 14.8996C17.5171 15.0496 17.5171 15.2746 17.3707 15.4246L15.6135 17.2621C15.5403 17.3371 15.4671 17.3746 15.4305 17.3746Z"
        fill="#001450"
      />
      <path
        d="M19.1996 20.8631V19.5132C19.1996 17.9757 17.8085 17.0757 17.7353 17.0757C17.5523 16.9632 17.3326 17.0382 17.2228 17.1882C17.113 17.3757 17.1862 17.6007 17.3326 17.7132C17.3326 17.7132 18.4308 18.4257 18.4308 19.5132V20.8631C18.4308 21.0881 18.5773 21.2381 18.7969 21.2381C19.0165 21.2381 19.1996 21.0506 19.1996 20.8631Z"
        fill="#001450"
      />
    </g>
    <defs>
      <clipPath id="clip0_3400_11495">
        <rect
          width="20.3902"
          height="20.7375"
          fill="white"
          transform="translate(0.750977 0.501953)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PackageIconoServiciosPulidoEncerado;
