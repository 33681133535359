import React from "react";

const PackageIconoServiciosCambioFrentos = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.341 17.25C11.3997 17.25 10.4796 16.9641 9.69704 16.4284C8.91444 15.8927 8.30448 15.1314 7.94429 14.2406C7.58409 13.3498 7.48985 12.3696 7.67347 11.4239C7.8571 10.4783 8.31034 9.60964 8.97589 8.92786C9.64144 8.24608 10.4894 7.78178 11.4125 7.59367C12.3357 7.40557 13.2925 7.50211 14.1621 7.87109C15.0317 8.24007 15.7749 8.86491 16.2979 9.6666C16.8208 10.4683 17.0999 11.4108 17.0999 12.375C17.0999 13.6679 16.5985 14.9079 15.706 15.8221C14.8136 16.7364 13.6031 17.25 12.341 17.25V17.25ZM12.341 8.25C11.5445 8.25 10.766 8.49193 10.1038 8.94519C9.4416 9.39845 8.92547 10.0427 8.6207 10.7964C8.31592 11.5502 8.23618 12.3796 8.39155 13.1797C8.54692 13.9799 8.93044 14.7149 9.49359 15.2918C10.0568 15.8687 10.7743 16.2616 11.5554 16.4207C12.3365 16.5799 13.1461 16.4982 13.8819 16.186C14.6177 15.8738 15.2466 15.3451 15.6891 14.6667C16.1316 13.9884 16.3677 13.1908 16.3677 12.375C16.3677 11.281 15.9435 10.2318 15.1883 9.45819C14.4332 8.6846 13.4089 8.25 12.341 8.25V8.25Z"
      fill="currentColor"
    />
    <path
      d="M12.3407 15.375C11.7615 15.375 11.1953 15.1991 10.7137 14.8694C10.2321 14.5398 9.85669 14.0712 9.63503 13.5231C9.41338 12.9749 9.35538 12.3717 9.46838 11.7897C9.58138 11.2078 9.8603 10.6732 10.2699 10.2537C10.6794 9.83412 11.2013 9.5484 11.7693 9.43265C12.3374 9.31689 12.9263 9.3763 13.4614 9.60336C13.9965 9.83043 14.4539 10.2149 14.7757 10.7083C15.0975 11.2016 15.2693 11.7817 15.2693 12.375C15.2693 13.1707 14.9607 13.9337 14.4115 14.4963C13.8623 15.0589 13.1174 15.375 12.3407 15.375ZM12.3407 10.125C11.9063 10.125 11.4816 10.257 11.1204 10.5042C10.7592 10.7514 10.4777 11.1028 10.3114 11.514C10.1452 11.9251 10.1017 12.3775 10.1865 12.814C10.2712 13.2504 10.4804 13.6513 10.7876 13.966C11.0947 14.2807 11.4861 14.495 11.9122 14.5818C12.3382 14.6686 12.7799 14.624 13.1812 14.4537C13.5826 14.2834 13.9256 13.995 14.1669 13.625C14.4083 13.255 14.5371 12.82 14.5371 12.375C14.5371 11.7783 14.3057 11.206 13.8938 10.784C13.4819 10.3621 12.9232 10.125 12.3407 10.125V10.125Z"
      fill="currentColor"
    />
    <path
      d="M12.3399 22.125C11.0084 22.1246 9.69168 21.8379 8.47503 21.2835C7.25837 20.7292 6.1688 19.9195 5.27674 18.9068C4.38468 17.8941 3.70999 16.7009 3.29626 15.4044C2.88254 14.1078 2.73899 12.7368 2.8749 11.3798C3.01082 10.0229 3.42316 8.71029 4.08528 7.52683C4.74741 6.34336 5.64458 5.31539 6.7188 4.50935C7.79303 3.70331 9.02041 3.13714 10.3216 2.84743C11.6228 2.55773 12.9689 2.55094 14.2728 2.8275C14.3666 2.85246 14.4469 2.91449 14.4961 3C14.5476 3.08501 14.5659 3.18678 14.5473 3.285L13.9873 5.9475C15.1373 6.27505 16.1796 6.91495 17.0038 7.79951C17.828 8.68407 18.4034 9.7804 18.6693 10.9725L21.305 10.5225C21.4 10.5064 21.4973 10.5293 21.5759 10.5862C21.6154 10.6147 21.649 10.6509 21.6747 10.6928C21.7005 10.7346 21.7179 10.7812 21.726 10.83C21.8092 11.3407 21.8532 11.8573 21.8578 12.375C21.8578 14.9609 20.855 17.4408 19.0701 19.2693C17.2851 21.0978 14.8642 22.125 12.3399 22.125V22.125ZM12.3399 3.375C10.6023 3.375 8.90365 3.90284 7.45885 4.89177C6.01404 5.88071 4.88796 7.28631 4.22299 8.93085C3.55802 10.5754 3.38403 12.385 3.72303 14.1308C4.06203 15.8766 4.89879 17.4803 6.12749 18.739C7.35619 19.9976 8.92166 20.8548 10.6259 21.2021C12.3302 21.5493 14.0967 21.3711 15.7021 20.6899C17.3075 20.0087 18.6796 18.8552 19.645 17.3751C20.6104 15.8951 21.1256 14.155 21.1256 12.375C21.125 12.0203 21.1042 11.666 21.0634 11.3137L18.4497 11.76C18.3536 11.7751 18.2555 11.7523 18.1751 11.6962C18.0968 11.638 18.0441 11.5504 18.0287 11.4525C17.8316 10.2694 17.2943 9.17351 16.4856 8.30504C15.677 7.43657 14.6337 6.83506 13.4894 6.5775C13.3947 6.55707 13.3118 6.4991 13.2588 6.41625C13.2073 6.33259 13.1902 6.23151 13.2112 6.135L13.7639 3.51C13.2934 3.42762 12.8173 3.38248 12.3399 3.375V3.375Z"
      fill="currentColor"
    />
    <path
      d="M18.3891 11.7622C18.3132 11.7628 18.239 11.7392 18.1768 11.6947C18.0984 11.6364 18.0458 11.5488 18.0303 11.4509C17.8332 10.2678 17.2959 9.17193 16.4873 8.30346C15.6786 7.435 14.6354 6.83349 13.491 6.57592C13.3964 6.5555 13.3135 6.49753 13.2604 6.41467C13.209 6.33102 13.1919 6.22993 13.2128 6.13342L14.0255 2.24842C14.0355 2.20011 14.0547 2.15429 14.082 2.11361C14.1094 2.07292 14.1443 2.03816 14.1848 2.01133C14.2252 1.9845 14.2705 1.96613 14.3179 1.95726C14.3653 1.9484 14.414 1.94922 14.4611 1.95967C16.5086 2.41742 18.3761 3.49114 19.824 5.04319C21.2719 6.59523 22.2343 8.55488 22.5879 10.6709C22.6038 10.7689 22.5811 10.8693 22.5249 10.9501C22.4686 11.031 22.3833 11.0856 22.2877 11.1022L18.4513 11.7584L18.3891 11.7622ZM13.9962 5.93467C15.1463 6.26222 16.1886 6.90212 17.0128 7.78668C17.837 8.67124 18.4124 9.76757 18.6783 10.9597L21.7935 10.4272C21.4204 8.5969 20.5548 6.9101 19.2946 5.55752C18.0343 4.20495 16.4298 3.24056 14.6625 2.77342L13.9962 5.93467Z"
      fill="currentColor"
    />
    <path
      d="M6.15416 12.0742C6.35633 12.0742 6.52023 11.9063 6.52023 11.6992C6.52023 11.4921 6.35633 11.3242 6.15416 11.3242C5.95198 11.3242 5.78809 11.4921 5.78809 11.6992C5.78809 11.9063 5.95198 12.0742 6.15416 12.0742Z"
      fill="currentColor"
    />
    <path
      d="M11.9276 6.39063C12.1298 6.39063 12.2937 6.22273 12.2937 6.01563C12.2937 5.80852 12.1298 5.64062 11.9276 5.64062C11.7254 5.64062 11.5615 5.80852 11.5615 6.01563C11.5615 6.22273 11.7254 6.39063 11.9276 6.39063Z"
      fill="currentColor"
    />
    <path
      d="M9.82115 6.92383C10.0233 6.92383 10.1872 6.75594 10.1872 6.54883C10.1872 6.34172 10.0233 6.17383 9.82115 6.17383C9.61897 6.17383 9.45508 6.34172 9.45508 6.54883C9.45508 6.75594 9.61897 6.92383 9.82115 6.92383Z"
      fill="currentColor"
    />
    <path
      d="M8.02525 8.1543C8.22743 8.1543 8.39132 7.9864 8.39132 7.7793C8.39132 7.57219 8.22743 7.4043 8.02525 7.4043C7.82308 7.4043 7.65918 7.57219 7.65918 7.7793C7.65918 7.9864 7.82308 8.1543 8.02525 8.1543Z"
      fill="currentColor"
    />
    <path
      d="M6.75084 9.94531C6.95301 9.94531 7.11691 9.77742 7.11691 9.57031C7.11691 9.36321 6.95301 9.19531 6.75084 9.19531C6.54866 9.19531 6.38477 9.36321 6.38477 9.57031C6.38477 9.77742 6.54866 9.94531 6.75084 9.94531Z"
      fill="currentColor"
    />
    <path
      d="M12.7665 19.1113C12.9686 19.1113 13.1325 18.9434 13.1325 18.7363C13.1325 18.5292 12.9686 18.3613 12.7665 18.3613C12.5643 18.3613 12.4004 18.5292 12.4004 18.7363C12.4004 18.9434 12.5643 19.1113 12.7665 19.1113Z"
      fill="currentColor"
    />
    <path
      d="M6.29674 14.2734C6.49891 14.2734 6.66281 14.1055 6.66281 13.8984C6.66281 13.6913 6.49891 13.5234 6.29674 13.5234C6.09456 13.5234 5.93066 13.6913 5.93066 13.8984C5.93066 14.1055 6.09456 14.2734 6.29674 14.2734Z"
      fill="currentColor"
    />
    <path
      d="M7.17564 16.3066C7.37782 16.3066 7.54171 16.1387 7.54171 15.9316C7.54171 15.7245 7.37782 15.5566 7.17564 15.5566C6.97347 15.5566 6.80957 15.7245 6.80957 15.9316C6.80957 16.1387 6.97347 16.3066 7.17564 16.3066Z"
      fill="currentColor"
    />
    <path
      d="M8.67564 17.9023C8.87782 17.9023 9.04171 17.7344 9.04171 17.5273C9.04171 17.3202 8.87782 17.1523 8.67564 17.1523C8.47347 17.1523 8.30957 17.3202 8.30957 17.5273C8.30957 17.7344 8.47347 17.9023 8.67564 17.9023Z"
      fill="currentColor"
    />
    <path
      d="M10.6161 18.873C10.8182 18.873 10.9821 18.7052 10.9821 18.498C10.9821 18.2909 10.8182 18.123 10.6161 18.123C10.4139 18.123 10.25 18.2909 10.25 18.498C10.25 18.7052 10.4139 18.873 10.6161 18.873Z"
      fill="currentColor"
    />
    <path
      d="M14.8592 18.5801C15.0614 18.5801 15.2253 18.4122 15.2253 18.2051C15.2253 17.998 15.0614 17.8301 14.8592 17.8301C14.6571 17.8301 14.4932 17.998 14.4932 18.2051C14.4932 18.4122 14.6571 18.5801 14.8592 18.5801Z"
      fill="currentColor"
    />
    <path
      d="M16.6571 17.3398C16.8593 17.3398 17.0232 17.1719 17.0232 16.9648C17.0232 16.7577 16.8593 16.5898 16.6571 16.5898C16.4549 16.5898 16.291 16.7577 16.291 16.9648C16.291 17.1719 16.4549 17.3398 16.6571 17.3398Z"
      fill="currentColor"
    />
    <path
      d="M17.9315 15.5527C18.1337 15.5527 18.2976 15.3848 18.2976 15.1777C18.2976 14.9706 18.1337 14.8027 17.9315 14.8027C17.7293 14.8027 17.5654 14.9706 17.5654 15.1777C17.5654 15.3848 17.7293 15.5527 17.9315 15.5527Z"
      fill="currentColor"
    />
    <path
      d="M18.5301 13.4219C18.7323 13.4219 18.8962 13.254 18.8962 13.0469C18.8962 12.8398 18.7323 12.6719 18.5301 12.6719C18.328 12.6719 18.1641 12.8398 18.1641 13.0469C18.1641 13.254 18.328 13.4219 18.5301 13.4219Z"
      fill="currentColor"
    />
  </svg>
);

export default PackageIconoServiciosCambioFrentos;
