import React from "react";

const PanelExpand = () => (
  <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.453 12.6313C11.2029 12.625 10.9647 12.5171 10.7885 12.3302L1.11531 2.13422C0.940348 1.94363 0.842751 1.68882 0.843189 1.42377C0.843626 1.15871 0.942064 0.904229 1.11765 0.714219C1.20612 0.620636 1.31131 0.546414 1.42716 0.495836C1.54301 0.445259 1.66723 0.419326 1.79264 0.419533C1.91805 0.41974 2.04218 0.446082 2.15786 0.497042C2.27354 0.548002 2.37849 0.622571 2.46665 0.716445L11.457 10.2113L20.4786 0.746173C20.5671 0.652591 20.6723 0.578368 20.7881 0.527791C20.904 0.477213 21.0282 0.451281 21.1536 0.451488C21.279 0.451695 21.4031 0.478037 21.5188 0.528997C21.6345 0.579956 21.7395 0.654526 21.8276 0.7484C22.0026 0.938989 22.1002 1.19379 22.0997 1.45885C22.0993 1.7239 22.0009 1.97839 21.8253 2.1684L12.1185 12.3324C11.9416 12.5187 11.7032 12.6258 11.453 12.6313Z"
      fill="#D5000A"/>
  </svg>

);

export default PanelExpand;
