import React from "react";

const PackageIconoServiciosVerificacion = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
  >
    <g clipPath="url(#clip0_3400_11422)">
      <path
        d="M14.7224 18.9902C14.6126 18.9902 14.5394 18.9527 14.4662 18.8777L12.9287 17.3027C12.7822 17.1527 12.7822 16.9277 12.9287 16.7777C13.0751 16.6277 13.2947 16.6277 13.4412 16.7777L14.7224 18.0902L17.0287 15.7277C17.1751 15.5777 17.3947 15.5777 17.5412 15.7277C17.6876 15.8777 17.6876 16.1027 17.5412 16.2527L14.9787 18.8777C14.9055 18.9527 14.8322 18.9902 14.7224 18.9902Z"
        fill="currentColor"
      />
      <path
        d="M3.88578 18.2395H2.31168C1.83578 18.2395 1.43311 17.827 1.43311 17.3395V15.6895C1.43311 15.4645 1.57953 15.3145 1.79918 15.3145H4.36168C4.58132 15.3145 4.72775 15.4645 4.72775 15.6895V17.3395C4.72775 17.827 4.36168 18.2395 3.88578 18.2395ZM2.16525 16.027V17.302C2.16525 17.377 2.23846 17.452 2.31168 17.452H3.84918C3.92239 17.452 3.99561 17.377 3.99561 17.302V16.027H2.16525Z"
        fill="currentColor"
      />
      <path
        d="M4.80127 14.75H2.60484C2.3852 14.75 2.23877 14.6 2.23877 14.375C2.23877 14.15 2.3852 14 2.60484 14H4.80127C5.02091 14 5.16734 14.15 5.16734 14.375C5.16734 14.6 4.9843 14.75 4.80127 14.75Z"
        fill="currentColor"
      />
      <path
        d="M10.4757 12.9883H6.08287C5.86323 12.9883 5.7168 12.8383 5.7168 12.6133C5.7168 12.3883 5.86323 12.2383 6.08287 12.2383H10.4757C10.6954 12.2383 10.8418 12.3883 10.8418 12.6133C10.8418 12.8383 10.6954 12.9883 10.4757 12.9883Z"
        fill="currentColor"
      />
      <path
        d="M9.56055 14.3027H7.36412C7.14448 14.3027 6.99805 14.1527 6.99805 13.9277C6.99805 13.7027 7.14448 13.5527 7.36412 13.5527H9.56055C9.78019 13.5527 9.92662 13.7027 9.92662 13.9277C9.92662 14.1527 9.74358 14.3027 9.56055 14.3027Z"
        fill="currentColor"
      />
      <path
        d="M14.7584 10.7039C14.7217 10.7039 14.7217 10.7039 14.6851 10.7039C14.6485 10.7039 11.9762 10.2539 8.64496 10.2539C5.31371 10.2539 1.90925 10.6664 1.87264 10.7039C1.653 10.7414 1.50657 10.5914 1.46996 10.3664C1.43335 10.1789 1.57978 9.99141 1.79943 9.95391C1.83603 9.95391 5.2771 9.50391 8.64496 9.50391C12.0494 9.50391 14.795 9.95391 14.8316 9.95391C15.0146 9.99141 15.161 10.1789 15.1244 10.3664C15.0878 10.5539 14.9048 10.7039 14.7584 10.7039Z"
        fill="currentColor"
      />
      <path
        d="M3.52005 13.4402C2.86112 13.4402 2.31201 12.8777 2.31201 12.1652C2.31201 11.4527 2.86112 10.9277 3.52005 10.9277C4.17898 10.9277 4.72808 11.4902 4.72808 12.1652C4.72808 12.8402 4.17898 13.4402 3.52005 13.4402ZM3.52005 11.6777C3.2638 11.6777 3.04415 11.9027 3.04415 12.1652C3.04415 12.4277 3.2638 12.6527 3.52005 12.6527C3.7763 12.6527 3.99594 12.4277 3.99594 12.1652C3.99594 11.9027 3.7763 11.6777 3.52005 11.6777Z"
        fill="currentColor"
      />
      <path
        d="M13.0381 13.4398C12.3792 13.4398 11.8301 12.8773 11.8301 12.2023C11.8301 11.5273 12.3792 10.9648 13.0381 10.9648C13.697 10.9648 14.2461 11.5273 14.2461 12.2023C14.2461 12.8773 13.697 13.4398 13.0381 13.4398ZM13.0381 11.6773C12.7819 11.6773 12.5622 11.9023 12.5622 12.1648C12.5622 12.4273 12.7819 12.6523 13.0381 12.6523C13.2944 12.6523 13.514 12.4273 13.514 12.1648C13.514 11.9023 13.2944 11.6773 13.0381 11.6773Z"
        fill="currentColor"
      />
      <path
        d="M1.909 10.4777C1.8724 10.4777 1.79918 10.4777 1.76257 10.4402L0.737574 10.0277C0.517931 9.99023 0.334896 9.84023 0.225074 9.65273C0.115253 9.42773 0.0786457 9.20273 0.15186 8.97773L0.225074 8.71523C0.371503 8.22773 0.810788 7.92773 1.32329 7.92773H2.78757C2.8974 7.92773 3.04382 8.00273 3.11704 8.11523C3.19025 8.22773 3.19025 8.37773 3.11704 8.49023L2.23847 10.2527C2.20186 10.4027 2.05543 10.4777 1.909 10.4777ZM1.3599 8.64024C1.17686 8.64024 1.03043 8.75273 0.957217 8.94023L0.884003 9.20274C0.884003 9.24024 0.884003 9.24023 0.884003 9.27773C0.884003 9.27773 0.92061 9.31523 0.957217 9.31523C0.993824 9.31523 0.993824 9.31523 1.03043 9.35273L1.76257 9.65273L2.23847 8.67773H1.3599V8.64024Z"
        fill="currentColor"
      />
      <path
        d="M14.6487 10.4766C14.5023 10.4766 14.3925 10.4016 14.3193 10.2516L13.4041 8.41406C13.3309 8.30156 13.3675 8.15156 13.4041 8.03906C13.4773 7.92656 13.5871 7.85156 13.7336 7.85156H15.1978C15.6737 7.85156 16.113 8.18906 16.2961 8.63906L16.3693 8.90156C16.4425 9.12656 16.4059 9.38906 16.2961 9.57656C16.1862 9.76406 16.0032 9.91406 15.7836 9.95156L14.7586 10.4391C14.7219 10.4766 14.6853 10.4766 14.6487 10.4766ZM14.3193 8.63906L14.7952 9.61406L15.5273 9.31406C15.5639 9.31406 15.5639 9.31406 15.6005 9.27656C15.6371 9.27656 15.6371 9.23906 15.6737 9.23906C15.6737 9.23906 15.6737 9.20156 15.6737 9.16406L15.6005 8.90156C15.5273 8.71406 15.3809 8.60156 15.1978 8.60156H14.3193V8.63906Z"
        fill="currentColor"
      />
      <path
        d="M11.5739 16.025H2.45876C1.43376 16.025 0.591797 15.1625 0.591797 14.1125V11.7125C0.591797 11.2625 0.774833 10.85 1.06769 10.5125L1.50698 10.0625L2.93465 7.175C3.41055 6.1625 4.39894 5.5625 5.49715 5.5625H11.0614C12.1597 5.5625 13.2579 6.3875 13.6239 7.175L15.0516 10.0625L15.4909 10.5125C15.7838 10.8125 15.9668 11.2625 15.9668 11.7125V13.0625C15.9668 13.175 15.9302 13.25 15.857 13.325C15.7838 13.4 15.6739 13.4375 15.5641 13.4375C15.4543 13.4375 15.3445 13.4375 15.2347 13.4375C13.7704 13.4375 12.4159 14.375 11.9034 15.8C11.8668 15.95 11.7204 16.025 11.5739 16.025ZM5.49715 6.3125C4.6918 6.3125 3.95965 6.7625 3.59358 7.5125L2.1659 10.475C2.1659 10.5125 2.1293 10.55 2.09269 10.5875L1.58019 11.075C1.39715 11.2625 1.32394 11.4875 1.32394 11.75V14.15C1.32394 14.7875 1.83644 15.3125 2.45876 15.3125H11.3177C12.0132 13.7375 13.5141 12.6875 15.2347 12.6875V11.75C15.2347 11.4875 15.1248 11.2625 14.9784 11.075L14.5025 10.5875C14.4659 10.55 14.4659 10.5125 14.4293 10.475L13.0016 7.5125C12.7088 6.95 11.8668 6.3125 11.098 6.3125H5.49715V6.3125Z"
        fill="currentColor"
      />
      <path
        d="M14.6851 5.15134C14.2459 5.15134 13.6601 5.00134 13.1476 4.58884C12.7816 4.28884 12.3423 3.68884 12.2691 2.67634C12.2325 2.03884 12.4887 1.43884 13.0012 1.02634C13.6967 0.463841 14.7584 0.351341 15.82 0.726341C17.5405 1.32634 18.6753 2.52634 18.7119 2.60134C18.8584 2.75134 18.8584 2.97634 18.7119 3.12634C18.6387 3.20134 17.0646 4.96384 14.8316 5.18884C14.795 5.15134 14.7584 5.15134 14.6851 5.15134ZM14.6485 1.25134C14.2092 1.25134 13.77 1.36384 13.4771 1.62634C13.1476 1.88884 13.0012 2.22634 13.0012 2.63884C13.0378 3.23884 13.2575 3.72634 13.6235 4.02634C14.0262 4.36384 14.5021 4.43884 14.795 4.40134C16.2592 4.25134 17.4673 3.31384 17.9432 2.86384C17.5405 2.48884 16.6985 1.81384 15.6003 1.43884C15.2709 1.28884 14.9414 1.25134 14.6485 1.25134Z"
        fill="currentColor"
      />
      <path
        d="M3.19071 4.47728C2.60499 4.47728 1.98267 4.17728 1.47017 3.65228C0.518385 2.63978 0.152314 1.43978 0.115706 1.40228C0.0424921 1.21478 0.152314 1.02728 0.335349 0.952277C0.408564 0.914777 2.05588 0.277277 3.62999 0.914777C3.95946 1.06478 4.39874 1.36478 4.61838 1.92728C4.76481 2.26478 4.87463 2.82728 4.58178 3.57728C4.39874 4.02728 3.99606 4.36478 3.48356 4.47728C3.41035 4.43978 3.30053 4.47728 3.19071 4.47728ZM0.957671 1.51478C1.14071 1.88978 1.43356 2.56478 1.98267 3.12728C2.42196 3.57728 2.93446 3.80228 3.37374 3.68978C3.62999 3.65228 3.84963 3.46478 3.92285 3.23978C4.06928 2.86478 4.10588 2.48978 3.95946 2.18978C3.84963 1.85228 3.59338 1.66478 3.41035 1.58978C2.45856 1.21478 1.47017 1.40228 0.957671 1.51478Z"
        fill="currentColor"
      />
      <path
        d="M5.49693 6.31475C5.49693 6.31475 5.46032 6.31475 5.49693 6.31475C5.27729 6.31475 5.13086 6.12725 5.13086 5.93975C5.13086 5.86475 5.20407 4.02725 6.48532 3.23975C7.62014 2.56475 8.60854 2.41475 9.26747 2.37725C9.81657 2.33975 12.3059 2.30225 13.1478 2.33975C13.8434 2.33975 14.2094 2.37725 14.4657 2.37725H14.6487C15.088 2.41475 15.7469 2.33975 15.7835 2.33975C16.0032 2.30225 16.1496 2.45225 16.1862 2.67725C16.2228 2.86475 16.0764 3.05225 15.8568 3.08975C15.8201 3.08975 15.1246 3.16475 14.6121 3.12725H14.3925C14.1362 3.12725 13.7701 3.08975 13.0746 3.08975C12.196 3.08975 9.74336 3.08975 9.26747 3.12725C8.90139 3.16475 7.913 3.20225 6.81479 3.87725C5.89961 4.43975 5.8264 5.93975 5.8264 5.93975C5.8264 6.16475 5.67997 6.31475 5.49693 6.31475Z"
        fill="currentColor"
      />
      <path
        d="M5.97278 4.62617C5.89957 4.62617 5.78975 4.58867 5.71653 4.51367C5.71653 4.51367 5.0576 3.91367 4.21564 3.50117C3.70314 3.27617 2.4585 2.75117 2.4585 2.75117L2.75135 2.07617C2.75135 2.07617 3.996 2.63867 4.5085 2.86367C5.46028 3.27617 6.19242 3.95117 6.22903 3.98867C6.37546 4.13867 6.37546 4.36367 6.26564 4.51367C6.19242 4.58867 6.0826 4.62617 5.97278 4.62617Z"
        fill="currentColor"
      />
      <path
        d="M15.2342 21.4273C12.8913 21.4273 10.9512 19.4773 10.9512 17.0398C10.9512 14.6398 12.8547 12.6523 15.2342 12.6523C17.5771 12.6523 19.5172 14.6023 19.5172 17.0398C19.5172 19.4773 17.6137 21.4273 15.2342 21.4273ZM15.2342 13.4398C13.294 13.4398 11.6833 15.0523 11.6833 17.0773C11.6833 19.1023 13.2574 20.7148 15.2342 20.7148C17.211 20.7148 18.7851 19.1023 18.7851 17.0773C18.7851 15.0523 17.211 13.4398 15.2342 13.4398Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3400_11422">
        <rect
          width="19.4018"
          height="20.925"
          fill="transparent"
          transform="translate(0.115723 0.501953)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PackageIconoServiciosVerificacion;
